<template>
  <div>
    <CCard>
      <CCardHeader>
        Add Share Router
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CRow>
              <CCol sm="4">
                <CSelect
                  placeholder="Router"
                  :options="routerListItem"
                  prepend="Router"
                  :value.sync="router"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                  placeholder="Target User ID(eg:114)"
                  class="mb-4"
                  prepend="User ID"
                  type="text"
                  :value.sync="userId"
                >
                </CInput>
              </CCol>
              <CCol sm="4">
                <CInput
                  placeholder="Target User Email(eg:test@test.com)"
                  class="mb-4"
                  prepend="User mail"
                  type="text"
                  :value.sync="userMail"
                >
                </CInput>
              </CCol>
              <CCol sm="12">
                <CButton
                  color="primary"
                  square
                  @click="noticeMsgModal=true"
                >
                  Submit
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        Share List
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              hover
              small
              fixed
              table-filter
              sorter
              items-per-page-select
              :items-per-page="5"
              :fields="authListFields"
              :items="authListItem"
              :noItemsView="{ noItems: 'No Item~' }"
              pagination
            >
              <template #action="{item}">
                <td class="py-2">
                  <CButtonGroup>
                    <CButton
                      color="danger"
                      square
                      @click="deleteRouterAuth(item.id)"
                    >
                      Delete
                    </CButton>
                  </CButtonGroup>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Request Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
    <CModal
      title="Danger!"
      color="danger"
      :show.sync="noticeMsgModal"
    >
      <CCardTitle style="color: red">
        Notice:
      </CCardTitle>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CCardText>
              You are sharing router to other user.The user who get the authentication can see all your router
              interfaces and add port-forward to your router.
            </CCardText>
          </CCol>
          <CCol sm="12">
            <CCardText>
              User ID:{{ userId }}
            </CCardText>
          </CCol>
          <CCol sm="12">
            <CCardText>
              User ID:{{ userMail }}
            </CCardText>
          </CCol>
        </CRow>
      </CCardBody>
      <template #footer>
        <CButton @click="addAuthOfRouter" color="danger">I see</CButton>
        <CButton @click="noticeMsgModal=false" color="primary">Quit</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "Add",
  data() {
    const authListFields = [
      { key: "id", label: "ID" },
      { key: "router_id", label: "Router ID" },
      { key: "router_name", label: "Router Name" },
      { key: "router_host", label: "Router Host" },
      { key: "auth_user", label: "Auth User Email" },
      { key: "created_at", label: "Create Time" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
        _style: "width: 10%"
      }
    ];

    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      requestMsg: null,
      routerListItem: [],
      router: null,
      noticeMsgModal: false,
      userId: null,
      userMail: null,
      authListFields,
      authListItem: []
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/router/auth", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.authListItem = response.data.authList.map(item => {
              return {
                ...item,
                auth_user: item.auth_user.mail,
                router_id: item.router.id,
                router_name: item.router.name,
                router_host: item.router.host
              }
            });
          }
        });
      if (this.routerListItem.length === 0) {
        this.routerListItem = [{ label: "Select a router" }];
        this.$axios.get("user/router", {})
          .then((response) => {
            if (response.data.code === 200) {
              response.data.userRouter.map(item => {
                let label = item.name + "(" + item.host + ")";
                this.routerListItem.push({ value: item.id, label: label });
              });
            }
          });
      }
    },
    addAuthOfRouter: function() {
      this.noticeMsgModal = false;
      this.$axios.post("user/router/auth/add", {
        router_id: this.router,
        user_id: this.userId,
        user_mail: this.userMail
      })
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          this.onLoadPage();
        });
    },
    deleteRouterAuth: function(id) {
      this.$axios.delete("user/router/auth/"+id+"/delete", {})
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
          this.onLoadPage();
        });
    }
  }
};
</script>